

















































import {
  defineComponent,
  ref,
  computed,
  watchEffect,
} from "@vue/composition-api";
import moment from "moment";
import { Dog } from "@/store";
import { useGetDogStatus } from "@/composables/helpers/use-get-dog-status";
import { useGetDogTrainingTime } from "@/composables/helpers";

interface Props {
  dogs: Array<Dog>;
  loading: boolean;
  total: number;
  showDeleted?: boolean;
}

export interface Pagination {
  page: number;
  itemsPerPage: number;
  pageStart: number;
  pageStop: number;
  pageCount: number;
  itemsLenth: number;
}

interface Header {
  text: string;
  value: string;
  align?: string;
  sortable?: boolean;
}

export default defineComponent({
  name: "DogsTable",
  props: {
    dogs: Array,
    loading: Boolean,
    total: Number,
    showDeleted: Boolean,
  },
  setup(props: Props, { emit }) {
    const sortBy = ref<string>("");
    const sortDesc = ref<boolean>();
    const headers = computed<Header[]>(() => {
      //TODO add column header status when we add that data
      const heads = [
        { text: "Name", value: "name", sortable: true },
        { text: "Breed", value: "breed", sortable: true },
        { text: "Color", value: "color", sortable: true },
        { text: "Birth Date", value: "birthDate", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Training Hours", value: "totalTrainingTime", sortable: true },
      ];
      if (props.showDeleted) {
        heads.push({ text: "Deleted", value: "deleted", sortable: true });
      }
      return heads;
    });
    const displayDogs = computed<Dog[] | null>(() => {
      const dogs: Dog[] = props.dogs;
      dogs.forEach((dog) => {
        dog.status = useGetDogStatus(dog);
        dog.totalTrainingTime = dog.dogTrainings
          ? useGetDogTrainingTime(dog.dogTrainings).totalRegularMinutes +
            useGetDogTrainingTime(dog.dogTrainings).totalPublicMinutes
          : 0;
      });
      if (props.showDeleted) {
        return dogs;
      }
      return dogs.filter((dog) => dog.deleted === null);
    });

    const page = ref<number>(1);
    const itemsPerPage = ref<number>(10);
    const pageCount = computed<number>(() =>
      Math.ceil(props.total / itemsPerPage.value)
    );
    const search = ref<string>("");

    function getDate(date: string) {
      return moment(new Date(date).toISOString().substr(0, 10)).format(
        "MM/DD/YYYY"
      );
    }

    function rowClick($event: Dog) {
      emit("rowClick", $event);
    }

    watchEffect(() => {
      if (props.showDeleted) {
        sortBy.value = "deleted";
        sortDesc.value = true;
      } else {
        sortBy.value = "";
        sortDesc.value = false;
      }
    });

    function updateSortBy(sort: string) {
      if (sortBy.value !== sort) {
        sortBy.value = sort;
      } else {
        sortDesc.value = !sortDesc.value;
      }
    }

    function getTrainingHours(trainingTime: string) {
      const time = parseInt(trainingTime);
      if (time <= 0 || isNaN(time)) {
        return "0:00";
      }
      const hours = Math.floor(time / 60).toString();
      let minutes = (time % 60).toString();
      minutes = parseInt(minutes) < 10 ? `0${minutes}` : minutes.toString();
      return hours + ":" + minutes;
    }

    function getTotalPublicHours(item: Dog) {
      if (item.dogTrainings)
        return useGetDogTrainingTime(item.dogTrainings).totalPublicMinutes;
      else return 0;
    }

    function getTotalRegularHours(item: Dog) {
      if (item.dogTrainings)
        return useGetDogTrainingTime(item.dogTrainings).totalRegularMinutes;
      else return 0;
    }

    return {
      headers,
      page,
      pageCount,
      itemsPerPage,
      search,
      getDate,
      rowClick,
      sortBy,
      sortDesc,
      updateSortBy,
      displayDogs,
      getTrainingHours,
      getTotalPublicHours,
      getTotalRegularHours,
      useGetDogStatus,
    };
  },
});
