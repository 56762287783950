



























import {
  defineComponent,
  computed,
  onBeforeMount,
  ref,
} from "@vue/composition-api";
import { useStore, useDogService, useRouter } from "@/composables/providers";
import { Dog } from "@/store";
import DogTable from "@/components/tables/Dogs.vue";
import { DogGetters } from "@/store/dog";
import { DogActions } from "@/store/dog";

export default defineComponent({
  name: "DogsList",
  components: {
    DogTable,
  },
  setup() {
    const store = useStore();
    const service = useDogService();
    const router = useRouter();
    const showDeleted = ref<boolean>(false);
    const dogs = computed<Dog[]>(() => store.getters[DogGetters.DOGS]);
    const loading = computed<boolean>(() => store.getters[DogGetters.LOADING]);
    const total = computed<number>(() => store.getters[DogGetters.TOTAL]);

    onBeforeMount(async () => {
      await store.dispatch(DogActions.LIST, { service });
    });

    async function navigateToDog($event: Dog) {
      await store.dispatch(DogActions.SELECT, {
        id: $event.id,
        service,
      });
      router.push({
        name: "DogDetail",
        params: { id: $event.id.toString() },
      });
    }

    return {
      dogs,
      loading,
      total,
      navigateToDog,
      showDeleted,
    };
  },
});
